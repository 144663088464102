@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Kaisei+Opti&display=swap");
@import "~@ionic/angular/css/core.css";
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import "../node_modules/primeng/resources/primeng.css";
@import "../node_modules/primeicons/primeicons.css";
@tailwind base;
@tailwind components;
@tailwind utilities;
/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
/* Core CSS required for Ionic components to work properly */
/* Basic CSS for apps built with Ionic */
/* Optional CSS utils that can be commented out */
/** Colors variables */
/** share button variables */
/** share buttons colors */
/** Colors variables */
/** share button variables */
/** share buttons colors */
.sb-group,
.sb-button {
  display: inline-flex;
  align-items: flex-start;
}

.sb-group {
  flex-wrap: wrap;
}

.sb-button {
  margin: var(--sb-margin);
}

/** Default style */
.sb-wrapper {
  font-size: inherit;
  cursor: pointer;
  position: relative;
  outline: 0;
  min-width: 4.125em;
  height: 2.5em;
  border: none;
  border-radius: 1px;
  padding: 0;
  line-height: 2.571em;
  background-color: transparent;
  -webkit-print-color-adjust: exact;
}
.sb-wrapper .sb-icon,
.sb-wrapper .sb-text {
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
}
.sb-wrapper .sb-content {
  flex: 1;
  display: flex;
  height: 100%;
  width: 100%;
  position: relative;
}
.sb-wrapper .sb-text {
  flex: 1;
  height: 100%;
  white-space: nowrap;
  padding: 0 0.7em;
}
.sb-wrapper .sb-icon {
  text-align: center;
  width: 100%;
  height: 100%;
  font-size: 1.2em;
  min-width: 2em;
}
.sb-wrapper .sb-text {
  font-weight: bold;
}

.sb-default .sb-wrapper {
  color: white;
  border-radius: 4px;
  overflow: hidden;
  background-color: var(--button-color) !important;
}
.sb-default .sb-wrapper.sb-facebook {
  background-color: #4267B2;
}
.sb-default .sb-wrapper.sb-twitter {
  background-color: #00acee;
}
.sb-default .sb-wrapper.sb-google {
  background-color: #db4437;
}
.sb-default .sb-wrapper.sb-mix {
  background-color: #ff8226;
}
.sb-default .sb-wrapper.sb-line {
  background-color: #00b900;
}
.sb-default .sb-wrapper.sb-linkedin {
  background-color: #006fa6;
}
.sb-default .sb-wrapper.sb-pinterest {
  background-color: #bd081c;
}
.sb-default .sb-wrapper.sb-reddit {
  background-color: #ff4006;
}
.sb-default .sb-wrapper.sb-tumblr {
  background-color: #36465d;
}
.sb-default .sb-wrapper.sb-whatsapp {
  background-color: #25d366;
}
.sb-default .sb-wrapper.sb-messenger {
  background-color: #0080FF;
}
.sb-default .sb-wrapper.sb-telegram {
  background-color: #0088cc;
}
.sb-default .sb-wrapper.sb-xing {
  background-color: #006567;
}
.sb-default .sb-wrapper.sb-sms {
  background-color: #20c16c;
}
.sb-default .sb-wrapper.sb-email {
  background-color: #FF961C;
}
.sb-default .sb-wrapper.sb-viber {
  background-color: #665ca7;
}
.sb-default .sb-wrapper.sb-vk {
  background-color: #4C75A3;
}
.sb-default .sb-wrapper.sb-copy {
  background-color: #607D8B;
}
.sb-default .sb-wrapper.sb-print {
  background-color: #765AA2;
}
.sb-default .sb-wrapper.sb-expand {
  background-color: #FF6651;
}
.sb-default .sb-wrapper.sb-show-icon.sb-show-text .sb-text {
  filter: none;
  padding-right: 1em;
  padding-left: 0;
}

.chatroom-button-wrapper {
  position: absolute;
  bottom: 40px;
  right: 40px;
  width: 70px;
  height: 70px;
  background: var(--ion-color-primary);
  z-index: 999;
  color: white;
  border-radius: 35px;
  justify-content: center;
  display: flex;
  align-items: center;
  font-size: 36px;
  box-shadow: 1px 2px 1px var(--ion-color-primary-shade);
  cursor: pointer;
}
.chatroom-button-wrapper .box {
  width: 180px;
  height: 75px;
  background-color: var(--ion-color-primary);
  color: #fff;
  padding: 8px;
  position: absolute;
  margin: 40px;
  float: left;
  font-size: 14px;
  top: -120px;
  border-radius: 7px;
  left: -190px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: 1px 2px 1px var(--ion-color-primary-shade);
}
.chatroom-button-wrapper .box.arrow-bottom:after {
  content: " ";
  position: absolute;
  right: 4px;
  bottom: -6px;
  border-top: 10px solid var(--ion-color-primary);
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
  border-bottom: none;
}
.chatroom-button-wrapper .box.arrow-bottom:before {
  content: " ";
  position: absolute;
  right: 3px;
  bottom: -8px;
  border-top: 9px solid var(--ion-color-primary-shade);
  border-right: 9px solid transparent;
  border-left: 9px solid transparent;
  border-bottom: none;
}

#chatroom {
  width: 100vw;
  background-image: url("/assets/images/pexels-mart-production-7089339.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  height: 100vh;
}

#chatroom > div {
  width: 100%;
  max-width: 960px;
  display: flex;
  align-items: flex-end;
}

.p-tabview .p-tabview-nav {
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
}

.p-dialog .p-dialog-header {
  background-color: var(--ion-color-primary-shade) !important;
  color: white !important;
}

.circle-btn {
  background: linear-gradient(120deg, #acd3f0, #8dcdff, #8dcdff, #8dcdff, #acd3f0, transparent);
  box-shadow: 2px 1px 3px 1px #8dcdff;
  color: white;
}
.circle-btn:hover {
  background: linear-gradient(0deg, #acd3f0, #b6cbdb, #a1a6ac, #a1a6ac, #b6cbdb, transparent);
  box-shadow: 2px 1px 3px 1px #a1a6ac;
  color: #ffffff;
}

.active-circle {
  background: linear-gradient(120deg, #eeaaaa, #f16868, #f16868, #f16868, #eeaaaa, transparent);
  box-shadow: 2px 1px 3px 1px #f16868;
  color: white;
}
.active-circle:hover {
  background: linear-gradient(0deg, #eeaaaa, #f8b2b2, #f8b2b2, #f8b2b2, #b6cbdb, transparent);
  box-shadow: 2px 1px 3px 1px #f8b2b2;
  color: #ffffff;
}

:host {
  font-family: "noto serif tc", "思源宋體", serif;
}

::-webkit-scrollbar {
  display: none;
}

html ::-webkit-scrollbar, body ::-webkit-scrollbar {
  width: 0px;
}

ion-content {
  --background:url("/assets/images/mottled.png") repeat rgb(250 250 250);
}